import { useEffect } from 'react';

const useScript = (url, setLoaded) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [setLoaded, url]);
};

export default useScript;
