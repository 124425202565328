import './App.css';
import { parseQueryString } from './Converters';
import TapPaymentCard from './tapCard';

function App() {
  const url = window.location.href;
  const {language, color, placeholderColor, paymentApiKey} = parseQueryString(url);
  const setPaymentCard = (data) => window.ReactNativeWebView.postMessage(data);
  return (
    <div className="App">
     <TapPaymentCard paymentApiKey={paymentApiKey} language={language} color={color} placeholderColor={placeholderColor} setPaymentCard={setPaymentCard} />
    </div>
  );
}

export default App;
